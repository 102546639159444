import { useStaticQuery, graphql } from "gatsby"

const useFooterData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsBlog(sort: { order: DESC, fields: meta___publishedAt }) {
        nodes {
          title
          slug
          excerptFromBody
          heroImage {
            alt
            gatsbyImageData
          }
          author {
            name
            slug
          }
          category {
            name
            slug
          }
        }
      }
    }
  `)
  return data.allDatoCmsBlog.nodes
}

export default useFooterData
