import React, { useState } from "react"

import Seo from "../components/SEO"
import { useBlogPosts } from "../system/DatoCms/BlogPostData"
import { Main, BlogCard, SearhBar } from "../components/UI"
import Layout from "../components/Layout"
import SearchHits from "../components/SearchHits/SearchHits"
import CategoriesBox from "../components/CategoriesBox/CategoriesBox"
import SectionTitle from "../components/SectionTitle"
import MasonryLayoutWrapper from "../components/MasonryLayoutWrapper"

const BlogPage = () => {
  const canonicalUrl = `https://thetoucan.app/blog/`
  const posts = useBlogPosts()
  const [searchTerm, setSearchTerm] = useState("")
  const inputHandler = e => {
    setSearchTerm(e.target.value)
  }

  return (
    <Layout isBlogPage>
      <Seo
        title="Blogs"
        description="Blogs by Toucan"
        canonicalUrl={canonicalUrl}
      />
      <Main className="relative bg-white pb-14 md:pb-16 lg:pb-32">
        <div className="mb-[28px] rounded-b-[50px] bg-header-cta px-4 pt-[137px] pb-[42px] md:pt-[200px] md:pb-[100px]">
          <div className="mx-auto max-w-[900px]">
            <h1 className="custom-text-shadow mb-8 text-center font-spartan text-4xl font-bold leading-[52px] text-white md:mb-16 md:text-6xl">
              The word from the bird.
            </h1>
            <div className="relative z-20">
              <SearhBar onChange={inputHandler} />
              <SearchHits searchTerm={searchTerm} posts={posts} />
            </div>
          </div>
        </div>
        <div className="relative z-10 mx-auto max-w-screen-2xl px-4 lg:px-20 xl:px-20">
          <CategoriesBox className="mb-14 md:mb-16" />
          <div className="mb-12">
            <SectionTitle>Featured Articles</SectionTitle>
          </div>
          <MasonryLayoutWrapper>
            {React.Children.toArray(
              posts.map(p => (
                <BlogCard
                  title={p.title}
                  slug={p.slug}
                  excerpt={p.excerptFromBody}
                  image={p.heroImage}
                  author={p.author}
                  category={p.category}
                />
              ))
            )}
          </MasonryLayoutWrapper>
        </div>
        <div>
          <img
            src="https://thetoucan.app/images/shape_thin.svg"
            alt="Toucan Svg"
            className="pointer-events-none absolute top-[60rem] -left-72 h-[684px] w-[684px]"
          />
          <img
            src="https://thetoucan.app/images/shape_thin.svg"
            alt="Toucan Svg"
            className="pointer-events-none absolute top-[115rem] right-0 h-[684px] w-[684px] rotate-180 transform"
          />
        </div>
      </Main>
    </Layout>
  )
}

export default BlogPage
